// extracted by mini-css-extract-plugin
export var aboutContainer = "about-module--aboutContainer--9vTOc";
export var aboutItem = "about-module--aboutItem--Qatzt";
export var abouth1 = "about-module--abouth1--fwanF";
export var aboutp = "about-module--aboutp--I3SjT";
export var btnOutline = "about-module--btnOutline--60ura";
export var FlexChoose = "about-module--FlexChoose--5R+ZV";
export var ChooseL = "about-module--ChooseL--F3vJg";
export var ChooseR = "about-module--ChooseR--+Y4E7";
export var chooseImgPerson = "about-module--chooseImgPerson--T3Esg";
export var chooseImg = "about-module--chooseImg--MZvpC";
export var FlexChooseR = "about-module--FlexChooseR--8Bwam";
export var chooseText = "about-module--chooseText--4-QoX";
export var ourMissionMain = "about-module--ourMissionMain--BJwWO";
export var FlexMission = "about-module--FlexMission--rMPtP";
export var Mission_text = "about-module--Mission_text--UG5va";
export var Mission_head = "about-module--Mission_head--AEnxc";
export var FlexChoose_h4 = "about-module--FlexChoose_h4--L35IG";
export var FlexStory = "about-module--FlexStory--qLxvA";
export var FlexStoryDiv = "about-module--FlexStoryDiv---K7bz";
export var OurStory = "about-module--OurStory--ZGkW1";
export var OurStoryAbove = "about-module--OurStoryAbove--lKsYV";
export var storyAbove = "about-module--storyAbove--2vGUT";
export var Story_text = "about-module--Story_text--Pkz7w";
export var Story_head = "about-module--Story_head--GzoX8";
export var bussinessman = "about-module--bussinessman--LPYxO";
export var team = "about-module--team--ripsU";
export var FlexTeam = "about-module--FlexTeam--HW3Qn";
export var Team1 = "about-module--Team1--54Kds";
export var Team2 = "about-module--Team2--pK+NO";
export var Team3 = "about-module--Team3--hDMaE";
export var Team4 = "about-module--Team4--86j+g";
export var Team5 = "about-module--Team5--uZiVz";
export var Flexbanner = "about-module--Flexbanner---7ma6";
export var FlexCompanies = "about-module--FlexCompanies--TJODc";
export var companies = "about-module--companies--bJ6Eh";
export var FlexChoose_icon = "about-module--FlexChoose_icon--zLVQ-";