import React from "react"
import SEO from "../components/seo"
import About from "../components/about/about"

const Page = location => (
  <>
    <SEO
      title="About Us | Master Trainer"
      description="Training Consultancy. Fast Skills assessment and training plans assigned in seconds.Our trainer helps  your organization training needs."
      url="/about"
      image="https://blogs.mycareerdreams.com/blogs/tips_header.webp"
      type="article"
    />
    <About location={location} />
  </>
)
export default Page
