import React from "react"
import loadable from "@loadable/component"
import MainHeader from "../main_header/main_header"
import MainFooter from "../footer/main_footer"
import * as classes from "../about/about.module.css"
import CEO from "../become_trainer/ceo_banner/ceo_banner"

const Banner = loadable(() => import("./banner/banner"), {
  fallback: <div></div>,
})
const OurMission = loadable(() => import("./our_mission"), {
  fallback: <div></div>,
})
const Whatwedo = loadable(() => import("./whatwedo/whatwedo"), {
  fallback: <div></div>,
})
const ChooseUs = loadable(() => import("./choose_us"), {
  fallback: <div></div>,
})
const OurStory = loadable(() => import("./our_story"), {
  fallback: <div></div>,
})
const Values = loadable(() => import("./values/values"), {
  fallback: <div></div>,
})
const OurTrainers = loadable(() => import("./our_trainers"), {
  fallback: <div></div>,
})
const Companies = loadable(() => import("./companies"), {
  fallback: <div></div>,
})

function About() {
  return (
    <div>
      <MainHeader />
      <Banner />

      <br />
      <div className={classes.ourMissionMain}>
        <OurMission />
        <CEO />
      </div>

      <Whatwedo />
      <ChooseUs />
      <OurStory />
      {/* <Values /> */}
      <br />

      <OurTrainers />
      <Companies />
      <MainFooter />
    </div>
  )
}

export default About
