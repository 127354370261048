import React from "react"
import * as classes from "./ceo_banner.module.scss"
// import ceo from "./static/ceo.webp"
import ceo from "./static/ceo.webp"
import AOS from "aos"
export default function CEO() {
  React.useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className={classes.main_div}>
      <div
        className={classes.text_div}
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <p>
          "At Master Trainers, we understand the drawbacks of outdated training
          methods: they're time-consuming and costly. That's why we offer a
          seamless, cost-effective solution to meet your training needs. Our
          approach is designed to save you time and money, providing the most
          efficient training experience possible. Join us in transforming your
          training projects."
        </p>
        <h4>Zain Khan</h4>
        <h6>Founder Master Trainers</h6>
        {/* <a
          href="https://forms.gle/9G2rXMT9zjCBaCP8A"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div className={classes.btnOutline}>
            <p> Become a Trainer </p>
          </div>
        </a> */}
      </div>
      <div
        className={classes.image_div}
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <img src={ceo} alt="CEO" />
      </div>
    </div>
  )
}
